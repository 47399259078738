import React from "react"
import Image from "../components/image"

export default function Pet(props) {
  return (
    <>
      <a
        href={props.pet.link}
        className="my-3 md:m-0 rounded overflow-hidden shadow-lg transition-all duration-300 transform hover:shadow-xl hover:border-pink hover:-translate-y-2"
        target="_blank"
        rel="noreferrer"
      >
        <Image imgName={props.pet.image} />
        
        <div className="p-6">
          <p className="text-pink font-bold uppercase">
            {props.pet.type} <span className="text-gray">[{props.pet.year}]</span>
          </p>
          <h2 className="font-semibold text-2xl leading-tight my-2">
            {props.pet.name}
          </h2>
          <p className="text-gray">
            {props.pet.description}
          </p>
        </div>
      </a>
    </>
  )
}
