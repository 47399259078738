import React from "react"
import Navbar from "../components/navbar"
import SEO from "../components/SEO"
import { Link, graphql } from "gatsby"
import Pet from "../components/pet"

export default function Pets(data) {
  const edges = data.data.allPetsJson.edges
  const pets = edges.map(edge => (
    <Pet key={edge.node.id} pet={edge.node} />
  ))

  return (
    <main>
      <SEO title="Pets" pathname="/pets" />

      <Navbar></Navbar>

      <div className="lg:max-w-screen-md mx-auto p-6 lg:pt-32 pt-24">
        <h1 className="lg:text-center tracking-tighter">
          <span className="text-pink">/</span>pets
        </h1>
        <p className="text-xl">
          I initiated a few side-projects with the purpose of either social good,
          business opportunity exploration, or sometimes just for fun. I still
          actively contribute to or manage a few of those initiatives along with
          their respective teams. I am open to new intriguing things if you have{" "}
          <Link to="/contact">something in mind</Link>.
        </p>
      </div>

      <div
        className="md:p-6 p-6 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
        id="showcase"
      >
        {pets}
      </div>
    </main>
  )
}


export const pageQuery = graphql`
  {
    allPetsJson {
      edges {
        node {
          id
          link
          image
          type
          year
          name
          description
        }
      }
    }
  }
`